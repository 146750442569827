<template>
  <div>
    <Table
      label="Profile Border"
      :actions="actions"
      :items="$store.getters['border/tableData']"
      :detail="$store.getters['border/detailData']"
      :fields="$store.getters['border/tableFields']"
      :loading="$store.state.border.loadingTable"
      :filter="$store.state.border.tableFilter"
      :sort="$store.state.border.tableSort"
      :sort-options="sortOptions"
      @changeFields="changeFields"
      @resetFields="resetFields"
      @filter="filterData"
      @sort="sortData"
      @load="loadData"
      @loadDetail="loadDetail"
      @resetDetail="resetDetail"
    />
    <!-- Delete Post modal -->
    <b-modal
      v-model="deleteModalVisible"
      title="Delete Border"
      ok-title="Yes, delete"
      cancel-variant="white"
      @ok="deleteBorder"
    >
      Are you sure want to delete this border?. This action cannot be undone.
    </b-modal>
  </div>
</template>

<script>
import Table from '@/layouts/components/Table.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    Table,
  },
  data() {
    return {
      deleteModalVisible: false,

      // Sort
      sortOptions: [
        {
          key: 'name', direction: 'desc', label: 'Desc', default: true,
        },
        { key: 'name', direction: 'asc', label: 'Asc' },
      ],
      actions: ['view',
        'update',
        'create', {
          type: 'other',
          children: [
            {
              label: 'Delete',
              event: this.confirmDelete,
              visibility: {
                callback: data => !data.deletedAt && this.canAccess('manage', 'ProfileBorder'),
              },
            },
          ],
        }],
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    changeFields(val) {
      this.$store.commit('border/SET_TABLE_FIELDS', val)
    },
    resetFields() {
      this.$store.commit('border/RESET_TABLE_FIELDS')
    },
    loadData() {
      return this.$store.dispatch('border/getTableData')
    },
    filterData(val) {
      this.$store.commit('border/SET_TABLE_FILTER', val)
    },
    sortData(val) {
      this.$store.commit('border/SET_TABLE_SORT', val)
    },
    loadDetail(name) {
      return this.$store.dispatch('border/getDetail', name)
    },
    resetDetail() {
      this.$store.commit('border/SET_DETAIL_DATA', undefined)
    },
    deleteBorder() {
      // eslint-disable-next-line no-console
      console.log('selected data', this.selectedData)
      this.$store.dispatch('border/delete', this.selectedData.name)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'The profile border has been deleted!',
              variant: 'success',
            },
          })

          this.deleteModalVisible = false
          this.loadData()
        })
    },
    confirmDelete(data) {
      this.selectedData = data.item
      this.deleteModalVisible = true
    },
  },
}
</script>
